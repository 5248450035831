import {URLSearchParams} from 'url'
import {IParam, IStrapiQuery} from '@common/types'
import {CardType, IEntityDetail, IEntityDetailResponse, IRequestFormData, IRequestPartnerFormData} from '@redux/types'

export const setRequestParams = (data: IParam | null | undefined): string => {
  if (!data || !Object.keys(data).length) {
    return ''
  }
  return '?' + new URLSearchParams(data).toString()
}

export const getPhoneNumbers = (phone: string) => {
  return parseInt(phone.replace(/[^0-9]/g, ''))
}

export const getCardClassNames = (cardType: CardType | null, mainClass: string, isBig: boolean = true) => {
  const classNames = []
  if (!cardType && isBig) {
    classNames.push(mainClass + '--size-big')
  }
  if (cardType === 'rounded-right') {
    classNames.push(`${isBig ? mainClass + '--size-big ' : ''}` + mainClass + '--radius-right')
  }
  if (cardType === 'circle') {
    classNames.push(mainClass + '--round')
  }
  if (cardType === 'rounded') {
    classNames.push(mainClass + '--radius-all')
  }
  if (cardType === 'square') {
    classNames.push(mainClass + '--size-small')
  }
  return classNames
}

export const getDomainFromUrl = (url: string) => {
  const regex = /^(?:(?:http[s]?|ftp):\/)?\/?(?:[^:\/\s]+?\.)*([^:\/\s]+\.[^:\/\s]+)/
  return url?.match(regex)?.[1] || null
}

export const isNotEmptyRichContent = (items: any) => {
  if (!items) {
    return false
  }
  return items?.hasOwnProperty('text') ? !!items?.text?.trim() : !!items?.some((c: any) => isNotEmptyRichContent(c?.children || c))
}

const isPartnersForm = (payload: IRequestFormData | IRequestPartnerFormData): payload is IRequestPartnerFormData =>
  (payload as IRequestPartnerFormData).nameCompanyContacts !== undefined

export const getRequestFormData = (payload: IRequestFormData | IRequestPartnerFormData): FormData => {
  let formData = new FormData()
  if (isPartnersForm(payload)) {
    if (payload.nameContact) formData.append('nameContact', payload.nameContact)
    if (payload.description) {
      formData.append('description', payload.description)
    }
    if (payload.file) {
      formData.append('file', payload.file)
    }
    if (payload.pipeline_id) {
      formData.append('pipeline_id', payload.pipeline_id)
    }
    if (payload.emailContacts) {
      formData.append('emailContacts', payload.emailContacts)
    }
    if (payload.nameCompanyContacts) {
      formData.append('nameCompanyContacts', payload.nameCompanyContacts)
    }
    if (payload.positionContacts) {
      formData.append('positionContacts', payload.positionContacts)
    }
    if (payload.siteContacts) {
      formData.append('siteContacts', payload.siteContacts)
    }
    if (payload.source) {
      formData.append('source', payload.source)
    }
    if (payload.surnameContacts) {
      formData.append('surnameContacts', payload.surnameContacts)
    }
    if (payload.tasks) {
      formData.append('tasks', payload.tasks)
    }
    if (payload.telegramContacts) {
      formData.append('telegramContacts', payload.telegramContacts)
    }
    if (payload.nameContact) {
      formData.append('nameContact', payload.nameContact)
    }
  } else {
    if (payload.description) {
      formData.append('description', payload.description)
    }
    if (payload.file) {
      formData.append('file', payload.file)
    }
    if (payload.email) {
      formData.append('email', payload.email)
    }
    if (payload.phone) {
      formData.append('phone', payload.phone)
    }
    if (payload.nameCompany) {
      formData.append('nameCompany', payload.nameCompany)
    }
    if (payload.nameContact) {
      formData.append('nameContact', payload.nameContact)
    }
    if (payload.pipeline_id) {
      formData.append('pipeline_id', payload.pipeline_id)
    }
    if (payload.responsible) {
      formData.append('responsible', payload.responsible)
    }
    if (payload.source) {
      formData.append('source', payload.source)
    }
    if (payload.tasks) {
      formData.append('tasks', payload.tasks)
    }
  }
  return formData
}

export const checkMobileSafari = () => {
  const agent = window.navigator.userAgent.toLowerCase()
  return /safari/.test(agent) && /iphone|ipod|ipad/.test(agent)
}

export const isCDN = () => process.env.NEXT_PUBLIC_CDN_URL !== undefined && process.env.NEXT_PUBLIC_DRAFT_MODE !== 'true'

export const getFromCDN = (src: string) => {
  return isCDN() ? `${process.env.NEXT_PUBLIC_CDN_URL}${src}` : src
}

export const getStrapiQuery = (params: IStrapiQuery): string => {
  let query: string = ''
  const {fields, pagination, populate, sort, locale} = params
  if (!!fields?.length) {
    query += fields.map((f, i) => `fields[${i}]=${f}`).join('&')
  }
  if (!!populate?.length) {
    const p = `populate=${typeof populate === 'string' ? populate : populate.join(',')}`
    query = !!query.length ? [query, p].join('&') : p
  }
  if (!!sort?.length) {
    const s = sort.map((s, i) => `sort[${i}]=${s.key}%3A${s.direction}`).join('&')
    query = !!query.length ? [query, s].join('&') : s
  }
  if (pagination) {
    const pg = [
      Object.entries(pagination)
        .map(k => `pagination[${k[0]}]=${k[1]}`)
        .join('&')
    ]
    query = !!query.length ? [query, pg].join('&') : pg.toString()
  }
  if (locale) {
    query = [query, `locale=${locale}`].join('&')
  }
  return query
}

export const getEntityDetail = (detail: IEntityDetailResponse): IEntityDetail => {
  if (detail?.media?.file?.data) {
    const image = detail?.media?.file?.data?.attributes
    const imageMobile = detail?.media?.imageMobile?.data?.attributes
    return {
      ...detail,
      media: {
        ...detail.media,
        image: image ?? null,
        imageMobile: imageMobile ?? null
      }
    } as IEntityDetail
  }
  return detail as IEntityDetail
}
